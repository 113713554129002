import React from "react";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import AboutMenu from "../../components/about/menu";
import SubTitle from "../../components/subtitle";
import Section from "../../components/section";
import H1 from "../../components/h1";
import Seo from "../../components/seo";

const medias = [
  {
    type: "tv",
    title: "NHK　経済最前線",
    term: "2002年11月〜2003年6月",
  },
  {
    type: "tv",
    title: "BSジャパン　アジアの風",
    term: "2012年6月〜2014年6月",
  },
  {
    type: "paper",
    title: "NNA　中国ビジネス講座",
    term: "2002年1月〜",
  },
  {
    type: "paper",
    title: "日本経済新聞　ビジネスの流儀",
    term: "2015年10月〜2017年10月",
  },
  {
    type: "paper",
    title: "フジサンケイビジネスアイ　チャイナウォッチング",
    term: "2005年12月〜2008年2月",
  },
  {
    type: "paper",
    title: "時事通信　水野真澄の目からうろこ",
    term: "2005年〜2018年5月",
  },
  {
    type: "paper",
    title: "三井住友銀行会報",
    term: "2009年12月〜2014年11月",
  },
  {
    type: "paper",
    title: "チャイナコンシェルジュ",
    term: "2002年10月〜2016年11月",
  },
];

const books = [
  {
    type: "ビジネス書",
    title: "初めてのベトナムビジネスガイド",
    description: "",
    image: (
      <StaticImage
        src="../../images/about/books/9784906950089.jpg"
        className="rounded-sm"
        alt="ビジネス書"
        width={200}
        height={300}
      />
    ),
    url: "https://chasechina.jp/item/373",
  },
  {
    type: "ビジネス書",
    title: "中国・外貨管理マニュアルQ&A（2022年改訂版）",
    description: `中国ビジネスにおいて、外貨管理制度の理解は避けて通れません。
    中国の法制度の変化は激しく、数年経過すれば状況は大きく変わります。特に外貨管理制度は経済環境、政策動向によって絶えず変化しています。
    本著は2021年10月時点の最新状況を、経常項目（貿易決済、非貿易項目）、資本項目（投融資、組織再編、不動産売買、保証）、クロスボーダー人民元、保税区域関連、個人の外貨管理などを全般的に解説しています。
    一問一答形式で、結論がすぐに把握できるだけでなく、根拠法も明確に記載しており、初心者の方、中国ビジネス経験が長い方を問わず、ご活用いただけます。`,
    image: (
      <StaticImage
        src="../../images/about/books/9784906950096.jpg"
        className="rounded-sm"
        alt="ビジネス書"
        width={200}
        height={300}
      />
    ),
    url: "https://chasechina.jp/item/372",
  },
  {
    type: "ビジネス書",
    title: "中国個人所得税の制度と実務",
    description: `中国の個人所得税は、2018年8月に抜本的な改正が行われ、2019年1月から施行されています。主には給与所得を中心とする総合所得課税が開始されていますが、中国の税制改正は日本のそれと比べても移行期間が短く、2019年当時、現場の実務はかなり混乱しました。
    本書は、2019年税制改正を織り込んだうえ、「かゆいところに手が届く実務書」として、第1部（基礎編）では中国個人所得税の基礎制度を、第2部（応用編）では実務を行う上で多くの担当者が疑問に思う事項を、なるべく平易な表現を意識して解説しています。`,
    image: (
      <StaticImage
        src="../../images/about/books/9784906950072.png"
        className="rounded-sm"
        alt="ビジネス書"
        width={200}
        height={300}
      />
    ),
    url: "https://chasechina.jp/item/371",
  },
  {
    type: "ビジネス書",
    title: "中国・増値税の制度と実務（チェイスチャイナ）",
    description:
      "現在進行形で改革が進む中国の増値税制度。 従来の『財貨の増値税』に加え、営改増試行措置の一環として営業税から増値税課税に切り替えられた『役務の増値税』について、その基礎と応用を実務の視点でわかりやすく解説します。",
    image: (
      <StaticImage
        src="../../images/about/books/series1_cover1122.jpg"
        className="rounded-sm"
        alt="ビジネス書"
        width={200}
        height={300}
      />
    ),
    url: "https://chasechina.jp/item/370",
  },
  {
    type: "ビジネス書",
    title:
      "初心者でもわかる！中国ビジネス担当者マニュアルステップワン－2（チェイスチャイナ）",
    description:
      "シリーズ第2作は、中国の会計・税務にスポットをあてました。 会計編では、中国会計制度の特徴と実務上考慮すべき点について、企業会計制度と新会計準則の相違点や企業所得税法との関係を踏まえて解説しています。 税務編では、中国の主要な税金である、企業所得税・個人所得税・増値税・営業税・消費税・付加税の各ポイントを解説しています。",
    image: (
      <StaticImage
        src="../../images/about/books/9784906950058-e1561340104683.png"
        className="rounded-sm"
        alt="ビジネス書"
        width={200}
        height={300}
      />
    ),
    url: "https://chasechina.jp/item/369",
  },
  {
    type: "ビジネス書",
    title:
      "初心者でもわかる！中国ビジネス担当者マニュアルステップワン（チェイスチャイナ）",
    description:
      "中国進出・組織再編・撤退、貿易・ビジネスモデル、外貨管理・クロスボーダー人民元、国際税務に関する事項を網羅しており、最短距離で中国ビジネスを俯瞰するための、担当者様向け初級マニュアルとなります。",
    image: (
      <StaticImage
        src="../../images/about/books/stepone_cover-e1553834226226.png"
        className="rounded-sm"
        alt="ビジネス書"
        width={200}
        height={300}
      />
    ),
    url: "https://chasechina.jp/item/368",
  },
  {
    type: "ビジネス書",
    title: "中国ビジネス投資Q&A（2017改訂版）",
    description:
      "「中国ビジネス投資Q&A」の初版発売（2002年）から15年、最終改定（2009年）から8年が経過し、中国のビジネス環境は大きく変化しています。 この度、ご要望に応えるべく、2017年現在の法令・制度改定、最新の中国ビジネストレンドを網羅し、全75の項目にまとめる大改訂を実施し、改訂版を出版する運びとなりました。 今回の改訂版は、20年にわたって中国ビジネスの最前線で日本企業をサポートしてきた水野真澄の集大成の一冊です。",
    image: (
      <StaticImage
        src="../../images/about/books/cover0511new.jpg"
        className="rounded-sm"
        alt="ビジネス書"
        width={200}
        height={300}
      />
    ),
    url: "https://chasechina.jp/item/4904",
  },
  {
    type: "ビジネス書",
    title: "中国におけるPE課税の理論と実務（チェイスチャイナ）",
    description:
      "恒久的施設（PE）認定の理論と対応策を分りやすく解説。さらに資料編として中国PE認定・課税に関する中国国内法16の原文と日本語訳を掲載。出張者（技術指導）・出向者の派遣、非居住者在庫、請負工事、コンサルティング役務遂行など豊富な実例を丁寧に解説しています。",
    image: (
      <StaticImage
        src="../../images/about/books/51LHgFWQbpL.jpg"
        className="rounded-sm"
        alt="ビジネス書"
        width={200}
        height={300}
      />
    ),
    url: "https://chasechina.jp/item/3210",
  },
  {
    type: "ビジネス書",
    title: "変わる！中国加工貿易の新マニュアル（エヌ・エヌ・エー）",
    description:
      "好評を頂いた加工貿易マニュアル（2007年春出版）を、加工貿易制度の目まぐるしい変更に合わせて大幅に改訂。さらに、来料加工厰の外資企業転換、転換後の企業運営の注意点を加筆した内容となっています。",
    image: (
      <StaticImage
        src="../../images/about/books/book2011_1.jpg"
        className="rounded-sm"
        alt="ビジネス書"
        width={200}
        height={300}
      />
    ),
  },
  {
    type: "ビジネス書",
    title: "中国保税開発区・倉庫活用実践マニュアル（エヌ・エヌ・エー）",
    description: "",
    image: (
      <StaticImage
        src="../../images/about/books/book2011_4.jpg"
        className="rounded-sm"
        alt="ビジネス書"
        width={200}
        height={300}
      />
    ),
  },
  {
    type: "ビジネス書",
    title: "中国ビジネス組織変更・撤退完全マニュアル（明日香出版社）",
    description: "",
    image: (
      <StaticImage
        src="../../images/about/books/book8.jpg"
        className="rounded-sm"
        alt="ビジネス書"
        width={200}
        height={300}
      />
    ),
  },
  {
    type: "ビジネス書",
    title: "中国　貿易・流通の開放とビジネス制度改革（エヌ･エヌ･エー）",
    description: "",
    image: (
      <StaticImage
        src="../../images/about/books/book5.jpg"
        className="rounded-sm"
        alt="ビジネス書"
        width={200}
        height={300}
      />
    ),
  },
  {
    type: "一般書",
    title:
      "知りたくなくても知っておかなきゃならない中国人のルール（明日香出版社）",
    description:
      "中国・香港でのビジネス駐在20余年の筆者自身のエピソードを中心に、彼らの考え方や日本人と異なる商習慣、それらへの対応策などを 100にわたって解説しています。",
    image: (
      <StaticImage
        src="../../images/about/books/book2011_5.jpg"
        className="rounded-sm"
        alt="一般書"
        width={200}
        height={300}
      />
    ),
  },
  {
    type: "一般書",
    title: "中国ビジネスコンサルタントができるまで（キョーハンブックス）",
    description: "",
    image: "",
  },
  {
    type: "共著",
    title: "改訂中国ビジネス会計税務用語集（キョーハンブックス）",
    description:
      "今回の改訂により、本文中には豊富な相互参照をつけて関連用語を読み進むことができるようにし、中国語とピンイン、ピンイン索引、法令URLも充実させた「専門家が使える用語集」になっています。",
    image: (
      <StaticImage
        src="../../images/about/books/book2011_6.jpg"
        className="rounded-sm"
        alt="共著"
        width={200}
        height={300}
      />
    ),
  },
];

const types = [
  {
    name: "ビジネス書",
    class: "bg-cyan-800",
  },
  {
    name: "一般書",
    class: "bg-teal-800",
  },
  {
    name: "共著",
    class: "bg-coral-800",
  },
  {
    name: "その他",
    class: "bg-cyan-600",
  },
];

const MizunoPage = () => {
  return (
    <Layout>
      <Seo title="代表取締役社長：水野真澄プロフィール" />
      <main>
        <H1>会社概要</H1>
        <AboutMenu />
        <div className="bg-cyan-50 space-y-3 md:space-y-5 py-3 md:py-5">
          <Section>
            <SubTitle>代表取締役社長：水野真澄プロフィール</SubTitle>
            <div className="text-center">
              <StaticImage
                src="../../images/about/mizuno.jpg"
                className="rounded-sm shadow-md hover:shadow-inner z-10"
                alt="水野真澄"
                width={200}
                height={300}
              />
            </div>
            <div className="mx-auto max-w-2xl leading-relaxed md:leading-loose text-sm md:text-base">
              1987年早稲田大学政治経済学部卒業、同年丸紅株式会社入社。
              <br />
              1997年丸紅香港華南有限公司赴任（在任中、丸紅廈門貿易有限公司社長、丸紅深セン貿易有限公司董事、丸紅広州貿易有限公司管理本部長を兼務）、
              2006年丸紅100％出資コンサルティング会社（M&C South China Ltd、M&C
              Shanghai Ltd）代表取締役社長就任を経て、2008年丸紅を退社。
              <br />
              同年年9月水野コンサルタンシーホールディングスを設立し、代表取締役社長就任。現在は、香港、上海、広州、深圳、日本、ベトナムに8か所の拠点を持つ。
              <br />
              中国・アジアで日本企業へのコンサルティング事業を推進する他、新聞や雑誌等の執筆、TV出演などの活動を行なう。
              <br />
              広州市投資促進局シンクタンクメンバー（広州市投資促進局専家庫専家）、肇慶市顧問、
              香港貿易発展局アドバイザー、横浜市（IDEC）アドバイザーを兼務。
              <br />
              また、上海総合保税区（現自由貿易試験区）の2009年優秀パートナーに選出されている（濾総保管[2010]
              52号）。
              <br />
              「中国外貨管理マニュアルQ&A」、「中国ビジネス投資Q&A」など多数の著作があり、中国語・韓国語にも翻訳されている。
              <br />
            </div>
          </Section>
          <Section>
            <SubTitle>テレビ出演・連載歴</SubTitle>
            <div className="mx-auto max-w-2xl text-sm md:text-base space-y-2 md:space-y-3">
              <div id="tv" className="space-y-1 md:space-y-2">
                <div className="text-center bg-gray-100 font-sans py-1">
                  テレビ
                </div>
                {medias
                  .filter((x) => x.type === "tv")
                  .map((m, index) => (
                    <div key={index} className="grid grid-cols-7">
                      <div className="col-span-5 pr-2">{m.title}</div>
                      <div className="col-span-2">{m.term}</div>
                    </div>
                  ))}
              </div>
              <div id="paper" className="space-y-1 md:space-y-2">
                <div className="text-center bg-gray-100 font-sans py-1">
                  新聞・雑誌など
                </div>
                {medias
                  .filter((x) => x.type === "paper")
                  .map((m, index) => (
                    <div key={index} className="grid grid-cols-7">
                      <div className="col-span-5 pr-2">{m.title}</div>
                      <div className="col-span-2">{m.term}</div>
                    </div>
                  ))}
              </div>
            </div>
          </Section>
          <Section>
            <SubTitle>水野真澄の著書、関連商品</SubTitle>
            <div className="mx-auto max-w-2xl text-sm md:text-base divide-y border-gray-200">
              {books.map((b, index) => (
                <div key={index} className="flex gap-3 md:gap-5 py-3 md:py-5">
                  <div className="w-1/4 md:w-1/5">
                    {b.url ? (
                      <a
                        href={b.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block hover:outline hover:outline-2 hover:outline-blue-400"
                      >
                        {b.image}
                      </a>
                    ) : (
                      b.image
                    )}
                  </div>
                  <div className="w-3/4 md:w-4/5">
                    <div className="font-sans mb-3 md:mb-5">
                      {b.url ? (
                        <a
                          href={b.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:text-blue-500"
                        >
                          {b.title}
                        </a>
                      ) : (
                        b.title
                      )}
                      <div>
                        <span
                          className={`font-sans font-extralight text-xs px-2 text-white ${
                            types.find((t) => t.name === b.type).class
                          }`}
                        >
                          {b.type}
                        </span>
                      </div>
                    </div>
                    <div className="text-xs md:text-sm">{b.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </Section>
        </div>
      </main>
    </Layout>
  );
};

export default MizunoPage;
