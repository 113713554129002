import React from "react";

const SubTitle = ({ id, children }) => {
  return (
    <div className="mx-auto max-w-2xl">
      <h2
        id={id}
        className="md:text-lg text-center border-b pb-2 border-gray-800 font-sans tracking-widest font-medium text-gray-800"
      >
        {children}
      </h2>
    </div>
  );
};

export default SubTitle;
